.testimonials {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-testimonials {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 2rem;
    text-transform: uppercase;
    color: #fff;
}

.testimonial-heading {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.testimonial-heading>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}
.testimonial-heading>:nth-child(2), 
.testimonial-heading>:nth-child(3) {
    font-weight: bold;
    font-size: 3rem;
}

.testimonial-review {
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

p span {
    color: var(--orange);
}

.right-testimonials {
    flex: 1;
    position: relative;
}

.reviewer {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}

#stylingPurpose-border {
    content: 'div';
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}

#stylingPurpose-card {
    content: "div";
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background: var(--planCard);
}

.arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

@media  screen and (max-width: 768px) {
    .testimonials {
        flex-direction: column;
    }

    .right-testimonials {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        position: relative;
    }

    #stylingPurpose-border,
    #stylingPurpose-card {
        position: relative;
        display: none;
    }
    
    .reviewer {
        top: 0;
        left: 0;
        position: relative;
        align-self: center;
    }

    .arrows {
        position: absolute;
        bottom: -3.5rem;
        left: 8.3rem;
        
    }
}