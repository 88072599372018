*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* font-size: 62.5% */
}

body {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  overflow-y: scroll;
  /* font-size: 1.6rem; */
}