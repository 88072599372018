.error-page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

h1 {
    font-size: 4rem;
}