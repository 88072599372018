.reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-reasons {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.left-reasons > img {
    object-fit: cover;
}

.left-reasons-imgOne {
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}

.left-reasons-imgTwo {
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}

.left-reasons-imgThree {
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}

.left-reasons-imgFour {
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;
}

.right-reasons {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-reasons-headingOne {
    font-weight: bold;
    color: var(--orange);
}

.right-reasons-headingTwo {
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
}

.right-reasons-answers {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right-reasons-answer {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    gap: 1rem;
}

.right-reasons-answer > img {
    width: 2rem;
    height: 2rem;
}

.partners-heading {
    color: var(--gray);
    font-weight: normal;
}

.partners-images {
    display: flex;
    gap: 1rem;
}

.partners-images > img {
    width: 2.5rem;
}

@media screen and (max-width: 768px) {
    .reasons {
        flex-direction: column;
    }    

    .left-reasons {
        grid-auto-rows: auto;
        overflow: hidden;
    }

    .left-reasons-imgOne {
        width: 7rem;
        height: 17rem;
    }

    .left-reasons-imgTwo {
        width: 15rem;
        height: 10rem;
    }

    .left-reasons-imgThree {
        width: 7rem;
    }

    .left-reasons-imgFour {
        width: 7rem;
        height: 6rem;
    }
}