.plans-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative; 
}

.plans-container-header {
    display: flex;
    gap: 2rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: #fff;
    gap: 2rem;
    padding: 1.5rem;
    width: 19rem;
}

.plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}
.plan:nth-child(2) svg {
    fill: #fff
}
.plan:nth-child(2) button {
    color: var(--orange);
}

.plan svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan-name {
    font-weight: bold;
    font-size: 1rem;
}

.plan-price {
    font-weight: bold;
    font-size: 3rem;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature img {
    width: 1rem;
}

.plan-forMoreInfo {
    font-size: .8rem;
}

.plans-blur-left {
    width: 32rem;
    height: 23rem;
    top: 9rem;
    left: 0;
}

.plans-blur-right {
    width: 32rem;
    height: 23rem;
    top: 13rem;
    right: 0;
}

@media  screen and (max-width: 768px) {
    .plans {
        flex-direction: column;
    }
    .plans-container-header {
        flex-direction: column;
        gap: 1rem;
        font-size: 1.9rem;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .plan:nth-child(2) {
        transform: none;
    }
}